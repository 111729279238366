<!--TODO : MAKE SURE TO NOTICE THE USER  WHILE TRYING REGISTERING WITH UNVALID INFORMATION. -->

<template>
  <div>

    <div class="" align="center">


      <q-form class="my-card">
        <q-card>


          <q-card-section>
            <b>
              Please choose your details in order to sign up.
            </b>
          </q-card-section>

          <q-card-section>
            <q-input
                outlined
                ref="name"
                color="black"
                v-model="userData.name"
                :rules="[ val => val && val.length > 0 || 'Please enter your name']"
                label="Name*"
                lazy-rules model-value="">
              <template v-slot:append>
                <q-icon name="account_circle"/>
              </template>
            </q-input>
          </q-card-section>


          <!--             email + validation-->
          <q-card-section>
            <q-input
                outlined
                ref="email"
                color="black"
                v-model="userData.email"
                :rules="[ val => val && val.length > 0 || 'Please enter your email']"
                label="Email*"
                lazy-rules
                type="email">
              <template v-slot:append>
                <q-icon name="mail"/>
              </template>
            </q-input>
          </q-card-section>

          <!--             password + validation-->
          <q-card-section>
            <q-input
                outlined
                ref="password"
                color="black"
                v-model="userData.password"
                :rules="[ val => val && val.length > 0 || 'Please enter your password']"
                :type="showPassword ? 'password' : 'text'"
                label="Password*"
                lazy-rules>
              <template v-slot:append>
                <q-icon
                    :name="showPassword ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="showPassword = !showPassword"
                />
              </template>
            </q-input>
          </q-card-section>

          <!--                  password repeat -->
          <q-card-section>
            <q-input
                outlined
                ref="password"
                color="black"
                v-model="repeatPassword"
                :rules="[ val => val && val.length > 0 || 'Passwords do not match']"
                :type="showPassword ? 'password' : 'text'"
                label="Repeat Password*"
                lazy-rules>
              <template v-slot:append>
                <q-icon
                    :name="showPassword ? 'visibility_off' : 'visibility'"
                    class="cursor-pointer"
                    @click="showPassword = !showPassword"
                />
              </template>
            </q-input>
          </q-card-section>


          <!--              submit button-->
          <q-card-section>
            <q-btn @click="register()" color="black" class="text-primary login-btn" label="Register" outline
            />
          </q-card-section>
        </q-card>

        <!--                switch to register page -->
        <q-card-section class="bottom-text">
          <b class="q-mr-md">Already A Member? click here to </b>
          <q-btn to="/login" flat class="register-btn" label="Login"/>
        </q-card-section>

      </q-form>
    </div>
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: "Form",
  data() {
    return {
      userData: {
        name: '',
        email: '',
        password: '',
      },
      repeatPassword: '',
      unValid: false
    }
  },
  methods: {
    ...mapActions('auth', ['registerUser']),

    async register() {
      const success = await this.registerUser(this.userData)
      if (success) await this.$router.push('/market-tracker');
      else this.unValid = true;
    }
  }
}
</script>

<style scoped>

.my-card {
  width: 500px;
  height: 400px;
}

.login-btn {
  margin-top: 10px;
}

.register-btn {
  color: #08be20;
  width: 70px;
  margin-bottom: 2px;
}
</style>
