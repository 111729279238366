<template>
  <div class="my-font">
    <div class="items-center q-my-xl" align="center">
      <q-img :src="require('../../assets/RPGYZER_Black.png')" width="450px"/>
      <div class="text-bold text-h4">
        Welcome to RPGYZER
      </div>
    </div>

    <div class="" align="center">
      <Form/>
    </div>
  </div>
</template>

<script>
import Form from "../../components/auth/RegisterForm"

export default {
  name: "Register",
  components: {Form}
}
</script>